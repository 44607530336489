.search-results {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 999;
  width: 100%;
  max-height: 200px;
  overflow-y: auto;
  background-color: #fff;
  border: 1px solid #ced4da;
  border-top: none;
  border-radius: 0 0 5px 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.search-results ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.search-results li {
  padding: 10px;
  cursor: pointer;
  border-bottom: 1px solid #eaeaea;
}

.search-results li:last-child {
  border-bottom: none; /* Remove a borda inferior do último item da lista */
}

.search-results li small {
  display: block;
}
.search-results li span.nome {
  display: inline-block;
  color: #333; /* Define a cor do texto como preto para o CPF/CNPJ */
}

.search-results li .numero-documento {
  display: block;
  font-weight: 400; /* Define o peso da fonte como 600 (semi-bold) para o CPF/CNPJ */
}

.search-results li .email {
  display: block;
}

.search-results li:hover {
  background-color: #f8f9fa;
}

.result-inactive {
  color: #ccc; /* Altera a cor do texto para cinza se o cliente estiver inativo */
}

.badges-container {
  margin-top: 4px;
}

.numero-documento,
.telefone,
.email {
  display: block;
  color: #666;
  font-size: 0.85rem;
}
