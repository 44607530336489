@import "app.scss";

:root {
  --text-error-color: #f34e4e;
  --control-disabled: #f5f6f8;
}
[data-bs-theme="dark"] {
  --control-disabled: #234e67;
}
@keyframes blink {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

:not(.no-required) label.required {
  &::before {
    content: "* ";
    color: var(--text-error-color);
    font-family: initial !important;
  }
}
.no-required .required {
  font-weight: normal !important;
  &::before {
    content: "" !important;
  }
}
.error-validation-message {
  margin-top: 0.25rem;
  font-size: 87.5%;
  color: var(--bs-form-invalid-color);
  position: relative;
  left: 20px;
  &::before {
    font-family: "Font Awesome 5 Free";
    content: "\f057";
    position: absolute;
    top: 0;
    left: -1.25rem;
  }
}

.success-validation-message {
  margin-top: 0.25rem;
  font-size: 87.5%;
  color: var(--bs-form-valid-color);
  position: relative;
  left: 20px;
  &::before {
    font-family: "Font Awesome 5 Free";
    content: "\f058";
    position: absolute;
    top: 0;
    left: -1.25rem;
  }
}

.warning-validation-message {
  margin-top: 0.25rem;
  font-size: 87.5%;
  color: #f7cc53;
  position: relative;
  left: 20px;
  &::before {
    font-family: "Font Awesome 5 Free";
    content: "\f06a";
    font-weight: 900;
    position: absolute;
    top: 0;
    left: -1.25rem;
  }
}

td .form-check .form-check-input {
  float: initial !important;
}

.btn-filter {
  position: fixed;
  right: 10px;
  top: 180px;
  z-index: 1000;

  box-shadow: 3px 3px 4px rgba(0, 0, 0, 0.2);
  border: none;
  border-radius: 30px !important;
  height: 45px;
  line-height: 45px;
  padding: 0;
  text-align: center;
  width: 45px;
}

.btn-new-record {
  position: fixed;
  right: 10px;
  top: 130px;
  z-index: 1000;

  box-shadow: 3px 3px 4px rgba(0, 0, 0, 0.2);
  border: none;
  border-radius: 30px !important;
  height: 45px;
  line-height: 45px;
  padding: 0;
  text-align: center;
  width: 45px;
}
.btn-cadeado {
  position: fixed;
  right: 10px;
  top: 130px;
  z-index: 1000;

  box-shadow: 3px 3px 4px rgba(0, 0, 0, 0.2);
  border: none;
  border-radius: 30px !important;
  height: 45px;
  line-height: 45px;
  padding: 0;
  text-align: center;
  width: 45px;
}
.btn-config {
  position: fixed;
  right: 10px;
  top: 130px;
  z-index: 1000;

  box-shadow: 3px 3px 4px rgba(0, 0, 0, 0.2);
  border: none;
  border-radius: 30px !important;
  height: 45px;
  line-height: 45px;
  padding: 0;
  text-align: center;
  width: 45px;
}
.form-w-xs {
  width: 176px;
}

.form-w-sm {
  width: 300px;
}

.form-w-md {
  width: 768px;
}

.form-w-lg {
  width: 992px;
}

.form-w-xl {
  width: 1140px;
}

.menu-item-color {
  color: rgba(var(--bs-link-color-rgb), var(--bs-link-opacity, 1)) !important;
  &::hover {
    cursor: pointer;
    --bs-link-color-rgb: var(--bs-link-hover-color-rgb) !important;
  }
}

.breadcrum-ico {
  margin-left: 10px;
  margin-top: -2px;
  cursor: pointer;
}

.breadcrum-ico.favorite svg {
  fill: #f7cc53;
}

.action > svg {
  cursor: pointer;
  margin-right: 10px;
}
.action > a {
  margin-right: 10px;
}
.modal-footer .btn {
  width: 150px;
}
.swal2-actions {
  display: flex;
  justify-content: center;
}

.swal2-actions button {
  width: 150px;
}

.swal2-actions button.swal2-cancel {
  margin-left: 10px;
}

.btn-cancel-register {
  width: 150px;
}
.btn-submit-register {
  width: 150px;
  margin-left: 10px;
}
.form-control {
  border: 1px solid #dbe2e8;
}
.form-control:disabled,
.form-control[readonly] {
  cursor: initial !important;
  background-color: var(--control-disabled);
  border: 1px solid var(--bs-border-color);
  opacity: 1;
}
.form-control:invalid,
.form-control.is-invalid {
  border-color: var(--bs-form-invalid-border-color);
}
input[type="date"].form-control:disabled,
input[type="date"].form-control[readonly] {
  text-align: center;
}

.form-control::placeholder {
  text-transform: none;
}

select.form-control {
  --bs-form-select-bg-img: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e");
  padding: 0.47rem 1.75rem 0.47rem 0.75rem;
  background-image: var(--bs-form-select-bg-img),
    var(--bs-form-select-bg-icon, none);
  background-repeat: no-repeat;
  background-position: right 0.75rem center;
  background-size: 16px 12px;
}

input[type="date"]:disabled::-webkit-calendar-picker-indicator {
  display: none;
}

input[type="month"]:disabled::-webkit-calendar-picker-indicator {
  display: none;
}

input[type="number"] {
  text-align: center;
}
input[data-smarteditor="Money"],
input[data-smarteditor="Decimal"],
input[data-smarteditor="Percent"] {
  text-align: right;
}
input[type="email"] {
  text-transform: lowercase;
}

input[data-text="normal"] {
  text-transform: initial;
}

input[data-text="upper"] {
  text-transform: uppercase;
}

input[data-text="lower"] {
  text-transform: lowercase;
}

.favorito-grupo-titulo {
  padding: 10px;
  margin: 5px;
  background-color: #f2f3fe;
  color: #7b8190;
}

#sidebar-menu ul li a .nav-icon {
  top: -6px;
  font-size: 18px;
}

.modal.show {
  display: block;
}

body[data-sidebar-size="sm"]
  .vertical-menu
  #sidebar-menu
  > ul
  > li
  > a
  .nav-icon {
  top: -7px;
  left: -12px;
}

body form footer {
  width: 100%;
  text-align: right;
}

body[data-vp="xs"],
body[data-vp="sm"] {
  .modal-footer {
    justify-content: center;
    .btn {
      width: 100%;
    }
  }
  form footer {
    float: initial !important;
    .btn-submit-register {
      margin-left: 0;
      margin-top: 10px;
    }
    .btn {
      width: 100%;
    }
  }
  .vertical-menu {
    width: 100%;
  }
}

.base-effect {
  width: 100%;
  height: 100%;
  overflow: hidden;
  position: relative;

  .circle-container {
    $particleNum: 200;
    $particleColor: hsl(180, 100%, 80%);

    position: absolute;
    transform: translateY(-10vh);
    animation-iteration-count: infinite;
    animation-timing-function: linear;

    .circle {
      width: 100%;
      height: 100%;
      border-radius: 50%;
      mix-blend-mode: screen;

      animation: fadein-frames 200ms infinite, scale-frames 2s infinite;

      @keyframes fade-frames {
        0% {
          opacity: 1;
        }

        50% {
          opacity: 0.7;
        }

        100% {
          opacity: 1;
        }
      }

      @keyframes scale-frames {
        0% {
          transform: scale3d(0.4, 0.4, 1);
        }

        50% {
          transform: scale3d(2.2, 2.2, 1);
        }

        100% {
          transform: scale3d(0.4, 0.4, 1);
        }
      }
    }

    $particleBaseSize: 8;

    @for $i from 1 through $particleNum {
      &:nth-child(#{$i}) {
        $circleSize: random($particleBaseSize);
        width: $circleSize + px;
        height: $circleSize + px;

        $startPositionY: random(10) + 100;
        $framesName: "move-frames-" + $i;
        $moveDuration: 28000 + random(9000) + ms;

        animation-name: #{$framesName};
        animation-duration: $moveDuration;
        animation-delay: random(37000) + ms;

        @keyframes #{$framesName} {
          from {
            transform: translate3d(
              #{random(100) + vw},
              #{$startPositionY + vh},
              0
            );
          }

          to {
            transform: translate3d(
              #{random(100) + vw},
              #{- $startPositionY - random(30) + vh},
              0
            );
          }
        }

        .circle {
          animation-delay: random(4000) + ms;
        }
      }
    }
  }
}

.input-group-text {
  height: 100%;
  background-color: transparent;
}

.inside-input {
  border: none;
  display: inline-block;
  position: absolute;
  top: 50%;
  margin-top: 1px;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  transition: color 0.3s;
  background-color: transparent !important;
  color: #9da9b9;
  z-index: 6;
}
.input-group-prepend .inside-input {
  left: 0px;
}
.input-group-append .inside-input {
  right: 0px;
}
.input-group .form-control {
  padding-left: 35px;
}
.is-invalid .inside-input {
  color: var(--text-error-color);
}

div.is-invalid label.form-label {
  color: var(--text-error-color);
  // animation: blink 0.5s infinite alternate;
}
.form-control.is-invalid::placeholder {
  color: var(--text-error-color);
}

.form-check-label {
  font-weight: 400;
}
::-webkit-search-cancel-button,
::-webkit-clear-button {
  --date-input-bg-img: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e");
  background-image: var(--date-input-bg-img),
    var(--bs-form-select-bg-icon, none);
  -webkit-appearance: none;
  color: rgba(0, 0, 0, 0.54);
  cursor: pointer;
  height: 1.5rem;
  margin-right: 0;
  width: 1.5rem;
}

::-webkit-calendar-picker-indicator {
  --date-input-bg-img: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27%3e%3cpath fill=%27none%27 stroke=%27%23343a40%27 stroke-linecap=%27round%27 stroke-linejoin=%27round%27 stroke-width=%272%27 d=%27M2 5l6 6 6-6%27/%3e%3c/svg%3e");
  background-image: var(--date-input-bg-img),
    var(--bs-form-select-bg-icon, none);
  color: rgba(0, 0, 0, 0);
  opacity: 1;

  width: 14px;
  height: 18px;
  cursor: pointer;
  border-radius: 50%;
  margin-left: 0.5rem;
}
.profile-cover-bg {
  background-image: url("../../assets/images/profile-bg.jpg");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

body[data-layout="horizontal"] #page-topbar {
  position: initial;
}

body[data-layout="horizontal"] .page-content {
  margin-top: 0px;
  padding-top: 20px;
}

body[data-layout="horizontal"] .navbar-nav .nav-item i {
  height: 16px;
  width: 16px;
  display: inline-block;
  font-size: 16px;
  color: #7b8190;
  margin-right: 7px;
  margin-top: -1px;
}

body[data-layout="horizontal"] .navbar-nav .nav-item a.dropdown-item {
  display: flex;
  .badge {
    margin-left: auto;
  }
}
.dropdown-menu.search {
  right: 0;
  width: 500px;
}

.dropdown-menu.profile {
  width: 280px;
}

.form-control:focus {
  border: 2px solid;
}

.float-label {
  background-color: $input-bg;
  position: absolute;
  // top: -2.5%;
  z-index: 6;
  margin-top: -0.5rem;
  transition-property: all;
  transition-timing-function: ease;
  line-height: 1;
  left: 0.5rem;
  padding: 2px 5px 2px 5px;
  font-size: small;
}

.form-group {
  margin-bottom: 1rem !important;
  position: relative;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-transition: "color 9999s ease-out, background-color 9999s ease-out";
  -webkit-transition-delay: 9999s;
  transition: color 9999s ease-out, background-color 9999s ease-out;
  transition-delay: 9999s;
}
body.renovar-sessao {
  overflow: hidden;
}
.base-renovar {
  width: calc(100vw);
  height: calc(100vh);
  position: absolute;
  left: 0;
  top: 0;
  background-color: gray;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-image: url("../images/auth-bg.jpg");
  z-index: 9999;
  .bg-overlay {
    opacity: 0.8;
    &.bg-white {
      background-color: var(--#{$prefix}white) !important;
    }
  }
}

body[data-bs-theme="dark"] .base-renovar .auth-logo-dark {
  display: none;
}

body[data-bs-theme="dark"] .base-renovar .auth-logo-light {
  display: inline;
}

body[data-bs-theme="light"] .base-renovar .auth-logo-dark {
  display: inline;
}

body[data-bs-theme="light"] .base-renovar .auth-logo-light {
  display: none;
}

body[data-bs-theme="dark"] .auth-logo-dark {
  display: none;
}

body[data-bs-theme="dark"] .auth-logo-light {
  display: block !important;
}
.smart-tooltip {
  visibility: hidden;
  position: fixed;
  text-align: center;
  background: rgba(0, 0, 0, 0.7);
  color: #fff;
  padding: 5px;
  border-radius: 3px;
  white-space: pre-wrap;
  z-index: 999999;
  max-width: 250px;
}
.smart-tooltip.top::before {
  content: "";
  position: absolute;
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-top: 10px solid rgba(0, 0, 0, 0.7);
  top: 100%;
  left: 50%;
  transform: translateX(-50%);
}

.smart-tooltip.right::before {
  content: "";
  position: absolute;
  width: 0;
  height: 0;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  border-right: 10px solid rgba(0, 0, 0, 0.7);
  right: 100%;
  top: 50%;
  transform: translateY(-50%);
}

.smart-tooltip.left::before {
  content: "";
  position: absolute;
  width: 0;
  height: 0;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  border-left: 10px solid rgba(0, 0, 0, 0.7);
  left: 100%;
  top: 50%;
  transform: translateY(-50%);
}

.smart-tooltip.bottom::before {
  content: "";
  position: absolute;
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-bottom: 10px solid rgba(0, 0, 0, 0.7);
  bottom: 100%;
  left: 50%;
  transform: translateX(-50%);
}
.tooltip-icon {
  cursor: pointer;
}
.tooltip-overlay {
  height: 100%;
}
.main-content {
  min-height: calc(100vh);
}
table.table-summary {
  border-collapse: collapse;
  width: 100%;

  td {
    padding: 2px 0 !important;
  }

  td:last-child {
    text-align: right;
    font-weight: 500 !important;
  }

  tr:last-child > td {
    border-top: 1px solid #e1e7ec;
    font-size: 16px;
  }
}

label.form-label {
  margin-bottom: 0px;
}
.text-muted {
  color: #9da9b9 !important;
}
.header-title {
  letter-spacing: 0.04em;
  font-weight: normal;
}
.btn-block {
  width: 100%;
}
.select-box .option :nth-child(2) {
  margin-left: 10px;
}
.options-select-label {
  margin: 4px 5px;
  display: inline-block;
  font-size: 0.9em;
  text-transform: lowercase;
  text-decoration: none;
}

.filter-form {
  max-height: 450px;
  overflow-y: auto;
}
.card-button {
  cursor: pointer;
}

.form-group.disabled label.form-label {
  background: linear-gradient(
    to bottom,
    transparent 50%,
    var(--control-disabled) 50%
  ) !important;
}

.show-password {
  cursor: pointer;
}
.input-group-append.file {
  border: 1px solid var(--bs-border-color);
  cursor: pointer;
  min-width: 44px;
}

.input-group-append.file .input-group-text {
  color: white;
  border: none;
  border-radius: 0;
}
.input-group .input-group-append.file:last-of-type {
  border-radius: 0 0.25rem 0.25rem 0 !important;
}
.input-group .input-group-append.file:last-of-type span.input-group-text {
  border-radius: 0 0.25rem 0.25rem 0 !important;
}
.input-group-append.file .input-group-text.upload {
  background-color: var(--bs-primary);
}

.input-group-append.file .input-group-text.download {
  background-color: var(--bs-success);
}

.input-group-append.file .input-group-text.delete {
  background-color: var(--bs-danger);
}

.header-filter {
  cursor: pointer;
}

.input-group-append.button {
  border: 1px solid var(--bs-border-color);
  cursor: pointer;
  min-width: 44px;
  z-index: 6;
}

.input-group-append.button .input-group-text {
  color: white;
  border: none;
  border-radius: 0;
}
.input-group .input-group-append.button:last-of-type {
  border-radius: 0 0.25rem 0.25rem 0 !important;
}
.input-group .input-group-append.button:last-of-type span.input-group-text {
  border-radius: 0 0.25rem 0.25rem 0 !important;
}

.input-group-text.novo {
  background-color: var(--bs-success);
}
// table.context-menu tbody::after {
//   content: "";
//   display: block;
//   min-height: 200px;
// }

.avatar-notificacao {
  background-color: $light;
}

.note {
  z-index: 6;
  transition-property: all;
  transition-timing-function: ease;
  line-height: 1;
  padding: 2px 5px 2px 5px;
  font-size: smaller;
}
.eixo-descricao {
  background-color: var(--bs-white);
  position: absolute;
  top: 1.5rem;
  left: 0.5rem;
}

thead,
tbody,
tfoot,
tr,
td,
th {
  border-style: none;
}
// .dropup,
// .dropend,
// .dropdown,
// .dropstart,
// .dropup-center,
// .dropdown-center {
//   position: fixed;
// }
table td {
  vertical-align: middle;
}
.card-table td {
  background-color: var(--bs-table-active-bg);
}

.auth-logo img {
  height: auto;
  max-height: 120px;
}

/* Estilo geral para a tabela "detalhe" */
td table {
  width: 100%; /* Garante que a tabela interna ocupe todo o espaço da td */
  border-collapse: separate; /* Permite o espaçamento entre as linhas */
  border-spacing: 0 10px; /* Adiciona espaço entre as linhas da tabela interna */
  border: none !important;
}

/* Estilo para as tr internas da tabela "detalhe" */
td table tr {
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1); /* Efeito de sombra para parecer um card */
  border-radius: 5px; /* Bordas arredondadas */
}

/* Estilo para as td internas da tabela "detalhe" */
td table.detail-table-card tr td {
  padding: 10px; /* Espaçamento interno */
  border: none; /* Remove bordas internas da tabela detalhe */
  background-color: var(--bs-white);
  // filter: brightness(1.1); /* Clareia a cor em 10% */
}

/* Estilo opcional para ajustar espaçamento dentro da td que contém a tabela detalhe */
tr.tr-detail td[colspan] {
  padding: 0px 10px 0px 10px; /* Espaçamento interno extra para as td com colspan que contém a tabela */
}

#sidebar-menu ul li.mm-active > a .menu-item {
  color: #038edc !important;
}

.note.subtitle {
  position: absolute;
  top: 1.5rem;
  left: 0.5rem;
  background: var(--bs-white);
}

.input-group-append.button .input-group-text.procurar {
  background-color: var(--bs-primary);
}
.input-group-append.button .input-group-text.limpar {
  background-color: var(--bs-danger);
}

.input-group-append.button .input-group-text.novo {
  background-color: var(--bs-success);
}

.input-group-append.button .input-group-text.buscando {
  background-color: var(--bs-secondary);
}

.input-group-append.button .input-group-text.buscando .mdi-timer-sand {
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.page-content.maximizado {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 9999;
  padding: unset;
  background-color: var(--bs-white);
}

.badge-light-subtle {
  background-color: rgba(200, 201, 202, 0.2);
}

body.auth footer.footer {
  left: 20px;
}
